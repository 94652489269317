import React, { useState, useEffect, useRef, RefObject } from "react";
import { Outlet, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styles from "./Layout.module.css";

// import { useLogin } from "../../authConfig";

// import { LoginButton } from "../../components/LoginButton";
// import { IconButton } from "@fluentui/react";

import organizationMapping from "../../organizationMapping";
import emlogo from "../../assets/emlogo.svg";

const Layout = () => {
    const { organizationAbbreviation } = useParams<{ organizationAbbreviation: string }>(); // Hent parameteren

    // Find den matchende værdi baseret på regex-mønsteret
    const getOrganizationName = (abbreviation: string | undefined) => {
        if (!abbreviation) return "";

        for (const pattern in organizationMapping) {
            const regex = new RegExp(pattern);
            if (regex.test(abbreviation)) {
                return organizationMapping[pattern];
            }
        }

        return "Ukendt organisation"; // Returner en tom streng, hvis intet mønster matcher
    };

    const organizationName = getOrganizationName(organizationAbbreviation);

    const { t } = useTranslation();
    const [menuOpen, setMenuOpen] = useState(false);
    const menuRef: RefObject<HTMLDivElement> = useRef(null);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
            setMenuOpen(false);
        }
    };

    useEffect(() => {
        if (menuOpen) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [menuOpen]);

    const environmentName = import.meta.env.VITE_ENV_NAME || "";

    return (
        <div className={styles.layout}>
            <header className={styles.header} role={"banner"}>
                <div className={styles.headerContainer} ref={menuRef}>
                    <img
                        src={emlogo}
                        alt="Erhvervministeriets logo"
                        aria-label="Erhvervministeriets logo"
                        width="220px"
                        height="50px"
                        className={styles.emLogo}
                    />
                    <h3 className={styles.headerTitle}>
                        HR-chatbot | {organizationName}
                        {environmentName}
                    </h3>
                    {/* <Link to="/" className={styles.headerTitleContainer}>
                        <h3 className={styles.headerTitle}>{t("headerTitle")}</h3>
                    </Link>
                    <nav>
                        <ul className={`${styles.headerNavList} ${menuOpen ? styles.show : ""}`}>
                            <li>
                                <NavLink
                                    to="/"
                                    className={({ isActive }) => (isActive ? styles.headerNavPageLinkActive : styles.headerNavPageLink)}
                                    onClick={() => setMenuOpen(false)}
                                >
                                    {t("chat")}
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to="/qa"
                                    className={({ isActive }) => (isActive ? styles.headerNavPageLinkActive : styles.headerNavPageLink)}
                                    onClick={() => setMenuOpen(false)}
                                >
                                    {t("qa")}
                                </NavLink>
                            </li>
                        </ul>
                    </nav>
                    <div className={styles.loginMenuContainer}>
                        {useLogin && <LoginButton />}
                        <IconButton
                            iconProps={{ iconName: "GlobalNavButton" }}
                            className={styles.menuToggle}
                            onClick={toggleMenu}
                            ariaLabel={t("labels.toggleMenu")}
                        />
                    </div> */}
                </div>
            </header>

            <Outlet />
        </div>
    );
};

export default Layout;
