const organizationMapping: { [key: string]: string } = {
    "^dep": "Departementet",
    "^kfst": "Konkurrence- og Forbrugerstyrelsen",
    "^erst": "Erhvervsstyrelsen",
    "^pvs": "Patent- og Varemærkestyrelsen",
    "^sik": "Sikkerhedsstyrelsen",
    "^sfs": "Søfartsstyrelsen",
    "^nh": "Nævnenes Hus",
    "^ft": "Finanstilsynet"
};

export default organizationMapping;
