import { Example } from "./Example";
import { useTranslation } from "react-i18next";

import styles from "./Example.module.css";

interface Props {
    onExampleClicked: (value: string) => void;
    useGPT4V?: boolean;
}

export const ExampleList = ({ onExampleClicked, useGPT4V }: Props) => {
    const { t } = useTranslation();

    // const DEFAULT_EXAMPLES: string[] = [t("defaultExamples.1"), t("defaultExamples.2"), t("defaultExamples.3")];
    const DEFAULT_EXAMPLES: string[] = [
        //    "Hvor meget orlov får man, hvis man får tvillinger eller trillinger?",
        //    "Har jeg ret til omsorgsdage selvom jeg ikke er biologisk forælder?",
        //    "Hvor meget sommerferie kan jeg holde?",
        "Hvad sker der med mine resterende feriedage når jeg går på pension?",
        "Hvordan registrerer jeg overførsel af feriedage i mTIME?",
        "Hvor meget ferie har jeg som student?",
        "Betyder reglerne om øremærket orlov, at far skal holde orlov?",
        "Hvordan får jeg tildelt omsorgsdage og hvor mange dage får jeg?",
        "Hvor meget orlov får man som soloforælder?"
    ];

    // const GPT4V_EXAMPLES: string[] = [t("gpt4vExamples.1"), t("gpt4vExamples.2"), t("gpt4vExamples.3")];
    const GPT4V_EXAMPLES: string[] = [
        //    "Hvor meget orlov får man, hvis man får tvillinger eller trillinger?",
        //    "Har jeg ret til omsorgsdage selvom jeg ikke er biologisk forælder?",
        //    "Hvor meget sommerferie kan jeg holde?",
        "Hvad sker der med mine resterende feriedage når jeg går på pension?",
        "Hvordan registrerer jeg overførsel af feriedage i mTIME?",
        "Hvor meget ferie har jeg som student?",
        "Betyder reglerne om øremærket orlov, at far skal holde orlov?",
        "Hvordan får jeg tildelt omsorgsdage og hvor mange dage får jeg?",
        "Hvor meget orlov får man som soloforælder?"
    ];

    return (
        <ul className={styles.examplesNavList}>
            {(useGPT4V ? GPT4V_EXAMPLES : DEFAULT_EXAMPLES).map((question, i) => (
                <li key={i}>
                    <Example text={question} value={question} onClick={onExampleClicked} />
                </li>
            ))}
        </ul>
    );
};
